/* eslint-disable */
import './App.css';
import "./fonts/misaki_gothic.ttf";
import {useEffect, useRef, useState} from "react";
import html2canvas from "html2canvas";
import {TwitterIcon, TwitterShareButton} from "react-share";
import {Box, Button, Grid, Slider, TextareaAutosize, TextField, Typography} from "@mui/material";

function App() {

    const [name, setName] = useState("ノーコピーライトボーイ");
    const [title, setTitle] = useState("好きです");
    const [body, setBody] = useState("俺の彼女になってください。\n俺と付き合ってください。\n必ず幸せにするから、これからもずっと一緒にいてほしい。\n俺から離れるなよ。\n君のことを大切にする。\n君が好きです。僕に守らせてもらえないかな？\n初めて会った時から君しか見てないよ。\n明日からは彼氏として隣にいていいかな？\nもう少しだけ一緒にいたい。\nすごく可愛い。\n愛してる。\n会いたい。\n俺はお前に会うために生まれてきた。\nあんまり無理すんなよ。\n俺じゃだめ？\nもっと頼ってよ。\nもっとこっちにおいで。\n君以外に興味ないよ。\nあんまり隙見せんなよ。\n理由なんていらないだろう。俺のものになるんだ。\n引っ込んでろ！俺んだよ。\n俺がそばにいてやるよ。\n俺じゃダメか？\nこいつは俺のものだ。\n好きだ。気がおかしくなるほど。幸せにしたいのはお前だけだ。\nわざとだよ？\n一番・・・か・・・わいかったよ。");
    const [image, setImage] = useState("/images/boy01.jpg");
    const [resultImage, setResultImage] = useState(null);
    const [opacity, setOpacity] = useState(0.3)

    const bodyRef = useRef(null);
    const imageRef = useRef(null);

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleTitle = (event) => {
        setTitle(event.target.value);
    };

    const handleBody = (event) => {
        setBody(event.target.value);
    };

    const handleOpacity = (event) => {
        setOpacity(event.target.value);
    };

    const handleImage = (event) => {
        const files = event.target.files;
        if (files.length <= 0) {
            setImage(null);
            return;
        }

        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            console.log("url: " + e.target.result);
            setImage(e.target.result);
        };
        reader.readAsDataURL(file);
    };

    const onClickReset = () => {
        setName("");
        setTitle("");
        setBody("");
        setImage(null);
    }

    const onClickExport = () => {
        // オリジナル画像取得
        // alpha設定
        //
        const target = document.getElementById("result2");
        html2canvas(target).then(canvas => {
            const targetImgUri = canvas.toDataURL("img/png");
            setResultImage(targetImgUri);
        });
    }

    return (
        <div className="root">
            <p>ガラケーのメール受信画面が作成できます。</p>
            <p>全部ブラウザ上で動作しているのでサーバーへ画像の送信などは行っていません。</p>
            <p>気楽にお使い下さい。</p>
            <Box>
                <Button onClick={() => onClickReset()} variant="contained" component="label" sx={{mb: 2}}>リセット</Button>
            </Box>
            <div className="container">
                <div className="form">
                    <Box display="flex" justifyContent="center" alignItems="center"
                         sx={{flexDirection: "column", width: 1}}>
                        <TextField fullWidth label="名前" variant="standard" onChange={handleName}
                                   value={name} sx={{mb: 2}}/>
                        <TextField fullWidth label="件名" variant="standard" onChange={handleTitle}
                                   value={title} sx={{mb: 2}}/>

                        <TextField fullWidth multiline label="本文" onChange={handleBody} defaultValue={body} value={body}
                                   rows="10" sx={{mb: 2}}/>
                        <Button variant="contained" component="label" sx={{mb: 2}}>
                            画像選択
                            <input hidden type="file" accept="image/*" onChange={handleImage}/>
                        </Button>
                    </Box>
                </div>
                <Typography>透明度</Typography>
                <Slider onChange={handleOpacity}
                        defaultValue={opacity}
                        min={0.0}
                        max={1.0}
                        step={0.1}
                        valueLabelDisplay="auto"
                />
                プレビュー
                {/* backgroundバージョン */}
                <div style={{border: "solid"}}>
                    <div id="result2" className="phone-screen-2" style={{backgroundImage: `url(${image})`}}>
                        {/* 本文 */}
                        <div className="phone-body-2" ref={bodyRef}
                             style={{backgroundColor: `rgba(255, 255, 255, ${opacity})`}}>
                            <p className="misaki">
                                受信MAIL [1/500]<br/>
                                DATE: 2023/03/04 19:40<br/>
                                FROM: {name}<br/>
                                TITLE: {title}<br/>
                            </p>
                            <hr/>
                            <p className="misaki">
                                {body}
                            </p>
                            <p style={{textAlign: "right"}}>melpic.pages.dev</p>
                        </div>
                    </div>
                </div>
            </div>
            <Button onClick={() => onClickExport()} variant="contained" sx={{mb: 2}}>
                画像生成！（少し時間かかるかも）
            </Button>
            {resultImage ? (
                <div>
                    <img src={resultImage} width={320}/>
                    <p style={{textAlign: "center"}}>
                        <TwitterShareButton
                            title="めるぴくで作りました！"
                            url="https://melpic.pages.dev"
                            hashtags={["melpic", "めるぴく"]}
                        >
                            <TwitterIcon size={50} round/>
                        </TwitterShareButton>
                    </p>
                </div>
            ) : (<div></div>)}
            <hr/>
            <h1>作った経緯</h1>
            <p>
                <a href="http://melga.jp/" target="_blank">メル画メーカー</a>の画像がTwitterで流れてきて面白そうだと思って使ってみようとしたら、https対応して無くて新規登録ができませんでした。。。
            </p>
            <p>ブラウザの設定を変更すればフォームpostできそうだったけど自分でも作れるかなーと思って試しに作ってみました。</p>
            <h1>使用素材</h1>
            <h2>フォント</h2>
            <p><a href="https://littlelimit.net/misaki.htm" target="_blank">美咲フォント</a> (<a
                href="https://twitter.com/num_kadoma" target="_blank">Twitter</a>) (<a
                href="https://www.pixiv.net/users/1517981" target="_blank">pixiv</a>)</p>
            <h2>イラスト</h2>
            <p><a href="https://fromtheasia.com/illustration/nocopyrightmens" target="_blank">ノーコピーライトメンズ</a> (<a
                href="https://www.youtube.com/channel/UCMYTAPUljhOqiSgEvu3Z2Iw/about" target="_blank">YouTube</a>) (<a
                href="https://twitter.com/nocopyrightgirl" target="_blank">Twitter</a>) (<a
                href="https://nocopyrightgirl.fanbox.cc/" target="_blank">FANBOX</a>)</p>
            <p>
                <TwitterShareButton
                    title="めるぴくで作りました！"
                    url="https://melpic.pages.dev"
                    hashtags={["melpic", "めるぴく"]}
                >
                    <TwitterIcon size={50} round/>
                </TwitterShareButton>
            </p>
        </div>
    );
}

export default App;
